@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  p {
    @apply dark:text-black text-white;
    @apply font-dosis;
  }
}

@layer components {
  .button {
    @apply m-2 dark:bg-blue-100 bg-indigo-100 rounded-full py-1 px-6 text-black border sm:border-2 border-solid dark:border-blue-300 border-indigo-300 font-dosis text-sm sm:text-base;
  }
  .button-selected {
    @apply m-2 dark:bg-emerald-100 bg-lime-50 rounded-full py-1 px-6 text-black border sm:border-2 border-solid dark:border-emerald-400 border-lime-300 font-dosis text-sm sm:text-base;
  }
  .button-disabled {
    @apply m-2 dark:bg-gray-100 bg-gray-400 rounded-full py-1 px-6 text-black border sm:border-2 border-solid dark:border-gray-400 border-gray-200 font-dosis text-sm sm:text-base;
  }
}
